import React, { useState, useRef } from "react";
import { number, bool } from "prop-types";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";

import CheckMarkIcon from "@components/shared/Icons/Checkmark";
import Typography from "@components/shared/Typography";
import ScrappageDetailsModal from "../PriceDetail/ScrappageDetailsModal";
import DiscountDetailsModal from "../PriceDetail/DiscountDetailsModal";
import EcoBonusModal from "@components/PriceDetail/EcoBonusModal";
import ArrowRightIcon from "@components/shared/Icons/ArrowRight";
import { withTabNavigation } from "@hoc/withTabNavigation";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import {
  toggleScrappageModalEvent,
  ecologicalBonusClickEvent,
} from "@gtm/events/homepage";
import gtm from "@gtm/core";

import {
  color,
  fontWeight,
  font,
  prop,
  desktop,
  tabletLarge,
} from "@components/shared/utils";

const WrapperContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${desktop`
    margin-right: 0;
  `}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${color("white")};
  color: ${color("black")};
  position: relative;

  ${tabletLarge`
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    position: initial;
  `}
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

const StyledTypography = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  color: ${prop("color")};
  padding-bottom: 0.1rem;
  letter-spacing: 0.6px;
  font-size: 0.8rem;
  display: inline-block;
  text-align: right;
  text-transform: uppercase;
`;

const IconContainer = styled.div`
  color: ${({ active }) => (active ? color("black") : color("grey"))};
`;

const LinkStyledTypography = withTabNavigation(styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  margin-left: 0.5rem;
  font-size: 0.7rem;
  display: inline-block;
  text-align: right;
  cursor: pointer;
  color: ${prop("color")};
  text-transform: uppercase;
`);

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  color: ${color("amiOrange")};
  margin-left: 0.1rem;
`;

const DiscountStripe = ({
  isScrappageSelected,
  isScrappageEnabled,
  governmentDiscountPercentage,
  scrappageDiscountPercentage,
  withEcoBonus,
  isMovesProgramEnabled,
  disabled,
  ...props
}) => {
  const primaryColor = usePrimaryColor();
  const { t, i18n } = useTranslation();
  const containerRef = useRef(null);
  const isB2BSelected = useIsB2BSelected();

  const [isScrappageModalOpen, setIsScrappageModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEcoBonusModalOpen, setIsEcoBonusModalOpen] = useState(false);

  const openDetailsModal = () => {
    gtm.fire(
      ecologicalBonusClickEvent({
        displayClose: "Display",
        label: t("price.openDetails"),
      })
    );
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    gtm.fire(
      ecologicalBonusClickEvent({
        displayClose: "Close",
        label: "close",
      })
    );

    setIsDetailsModalOpen(false);
  };

  const openScrappageModal = () => {
    gtm.fire(
      toggleScrappageModalEvent({
        displayClose: "Display",
        label: t(isScrappageSelected ? "price.deactivate" : "price.clickHere"),
      })
    );

    setIsScrappageModalOpen(true);
  };

  const closeScrappageModal = () => {
    gtm.fire(
      toggleScrappageModalEvent({
        displayClose: "Close",
        label: "close",
      })
    );

    setIsScrappageModalOpen(false);
  };

  const toggleEcologicalBonus = () => {
    gtm.fire(
      ecologicalBonusClickEvent({
        displayClose: isEcoBonusModalOpen ? "Close" : "Display",
        label: isEcoBonusModalOpen
          ? ""
          : isB2BSelected
            ? t("price.ecologicalBonusB2B")
            : t("price.ecologicalBonus"),
      })
    );

    setIsEcoBonusModalOpen(!isEcoBonusModalOpen);
  };

  return (
    <WrapperContainer {...props} ref={containerRef}>
      <Container>
        {withEcoBonus && (
          <>
            {governmentDiscountPercentage !== 0 && (
              <LineContainer>
                <CheckMarkIcon width="26px" height="26px" checked />
                <StyledTypography color={primaryColor}>
                  {governmentDiscountPercentage}%
                </StyledTypography>
                <StyledTypography
                  margin="0 0 0 0.5rem"
                  data-id="discount-ecobonus-text"
                >
                  {t("price.ecoBonus")}
                </StyledTypography>
                <LinkStyledTypography
                  onClick={openDetailsModal}
                  color={primaryColor}
                >
                  {t("price.openDetails")}
                </LinkStyledTypography>
                <StyledArrowRightIcon height="0.6rem" width="0.6rem" />
              </LineContainer>
            )}
            {(governmentDiscountPercentage === 0 || isMovesProgramEnabled) && (
              <LineContainer>
                <CheckMarkIcon width="26px" height="26px" checked />
                <LinkStyledTypography
                  data-id="discount-ecobonus-text"
                  color={primaryColor}
                  onClick={toggleEcologicalBonus}
                >
                  {isB2BSelected
                    ? t("price.ecologicalBonusB2B")
                    : t("price.ecologicalBonus")}
                </LinkStyledTypography>
                <StyledArrowRightIcon height="0.6rem" width="0.6rem" />
              </LineContainer>
            )}
          </>
        )}
        {!withEcoBonus && isMovesProgramEnabled && (
          <LineContainer>
            <CheckMarkIcon width="26px" height="26px" checked />
            <LinkStyledTypography
              data-id="discount-ecobonus-text"
              color={primaryColor}
              onClick={toggleEcologicalBonus}
            >
              {isB2BSelected && i18n.exists("price.ecologicalBonusB2B")
                ? t("price.ecologicalBonusB2B")
                : t("price.ecologicalBonus")}
            </LinkStyledTypography>
            <StyledArrowRightIcon height="0.6rem" width="0.6rem" />
          </LineContainer>
        )}
        {isScrappageEnabled && (
          <LineContainer>
            <IconContainer active={isScrappageSelected}>
              <CheckMarkIcon width="26px" height="26px" checked />
            </IconContainer>
            {isScrappageSelected && (
              <StyledTypography color={primaryColor}>
                {scrappageDiscountPercentage}%
              </StyledTypography>
            )}
            <StyledTypography margin="0 0 0 0.5rem">
              {isScrappageSelected
                ? t("price.scrappBonusOn")
                : t("price.scrappBonusOff")}
            </StyledTypography>
            {!disabled && (
              <>
                <LinkStyledTypography
                  onClick={openScrappageModal}
                  color={primaryColor}
                >
                  {t(
                    isScrappageSelected ? "price.deactivate" : "price.clickHere"
                  )}
                </LinkStyledTypography>
                <StyledArrowRightIcon height="0.6rem" width="0.6rem" />
              </>
            )}
          </LineContainer>
        )}
        <DiscountDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={closeDetailsModal}
        />
        {isScrappageEnabled && (
          <ScrappageDetailsModal
            isOpen={isScrappageModalOpen}
            isScrappageSelected={isScrappageSelected}
            onClose={() => setIsScrappageModalOpen(false)}
            closeScrappageModal={closeScrappageModal}
          />
        )}
        {(withEcoBonus || isMovesProgramEnabled) && (
          <EcoBonusModal
            isOpen={isEcoBonusModalOpen}
            onClose={toggleEcologicalBonus}
          />
        )}
      </Container>
    </WrapperContainer>
  );
};

DiscountStripe.propTypes = {
  governmentDiscountPercentage: number.isRequired,
  scrappageDiscountPercentage: number.isRequired,
  isScrappageSelected: bool,
  isScrappageEnabled: bool,
  withEcoBonus: bool,
  disabled: bool,
  isMovesProgramEnabled: bool,
};

export default DiscountStripe;
